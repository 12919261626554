<op-autocompleter
  classes="wp-relations-autocomplete"
  data-qa-selector="wp-relations-autocomplete"
  [appendTo]="appendToContainer"
  [placeholder]="inputPlaceholder"
  [typeToSearchText]="inputPlaceholder"
  [resource]="autocompleterOptions.resource"
  [defaultData]="false"
  (open)="onOpen()"
  (change)="onWorkPackageSelected($event)"
  [getOptionsFn]="autocompleterOptions.getOptionsFn"
></op-autocompleter>
