<spot-selector-field
  labelWeight="bold"
  [reverseLabel]="true"
  [label]="text.ignoreNonWorkingDays.title"
>
  <spot-switch
    slot="input"
    name="weekdays_only"
    [disabled]="disabled"
    [ngModel]="!ignoreNonWorkingDays"
    (ngModelChange)="onToggle($event)"
    data-qa-selector="op-datepicker-modal--include-non-working-days"
  ></spot-switch>
</spot-selector-field>
