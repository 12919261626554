<div class="op-ckeditor--wrapper">
  <op-ckeditor
    [context]="context"
    [content]="initialContent"
    (onInitialized)="setup($event)"
    (onContentChange)="markEdited()"
  ></op-ckeditor>
</div>

<fieldset
  class="form--fieldset"
  *ngIf="resource && resource.attachments"
>
  <legend class="form--fieldset-legend">
    {{ text.attachments }}
  </legend>
  <op-attachments
    [resource]="resource"
    [destroyImmediately]="false"
  ></op-attachments>
</fieldset>
