<spot-selector-field
  labelWeight="bold"
  [reverseLabel]="true"
  [label]="text.scheduling.title"
>
  <spot-switch
    slot="input"
    name="scheduling"
    [ngModel]="scheduleManually"
    (ngModelChange)="onToggle($event)"
    data-qa-selector="op-datepicker-modal--scheduling-action"
  ></spot-switch>
</spot-selector-field>
