<span class="spot-icon spot-icon_{{content.iconClass}} op-files-tab--storage-info-box-icon"></span>

<div
  class="text-box"
>
  <div
    class="text-box-header"
  >
    <span [textContent]="content.header"></span>
  </div>
  <div
    class="text-box-content"
  >
    <span [textContent]="content.content"></span>
  </div>
</div>

<div
  class="button-box"
>
  <button *ngFor="let button of content.buttons"
          type="button"
          class="button op-files-tab--storage-info-box-button"
          (click)="button.action()"
  >
    <span class="button--text">{{button.label}}</span>
    <span class="spot-icon spot-icon_external-link"></span>
  </button>
</div>
