<input
  type="checkbox"
  class="spot-switch--input"
  [attr.name]="name"
  [disabled]="disabled"
  [tabindex]="tabindex"
  [ngModel]="checked"
  (ngModelChange)="onToggle($event)"
  #input
/>
<span
  class="spot-switch--fake"
  data-qa-selector="spot-switch-handle"
  [attr.data-qa-active]="checked || undefined"
></span>
