<div class="op-wp-embeded-graph work-packages-embedded-view--container loading-indicator--location" #graphContainer [ngStyle]="{height: chartHeight}">
  <canvas baseChart
          [datasets]="chartData"
          [labels]="chartLabels"
          [chartType]="chartType"
          [options]="internalChartOptions"
          *ngIf="hasDataToDisplay">
  </canvas>
  <op-no-results *ngIf="!hasDataToDisplay && initialized"
              [title]="text.noResults">
  </op-no-results>
</div>

